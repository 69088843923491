export const WORDS = [
  //'rhodes',
  //'chapel',
  //'marcon',
  //'wilson',
  //'barker',
  //'reader',
  //'school',
  //'upfold',
  //'browne',
  'young',
  //'cootes',
  //'carson',
  //'gibson',
  //'hillen',
  //'gibbon',
  //'mcghee',
  //'nairne',
  //'royall',
  //'verdon',
  //'vaiani',
  //'weaver',
  //'purvis',
  'crane',
  'rayer',
  'young',
  'scott',
  'smuts',
  'welch',
  'quinn',
  'leeke',
  'cooke',
  'north',
  'south',
  'humes',
  'butts',
  'sanny',
  'saxel',
  'brown',
  'drake',
  'emley',
  'fearn',
  'flack',
  'frude', //Until Easter
  'guppy',
  'hardy',
  'fives',
  'pupil',
  'teach',
  'sport',
  'pitch',
  'field',
  'drama',
  'stage',
  'music',
  'lower',
  'upper',
  'fifth',
  'sixth',
  'skirt',
  'board',
  'house',
  'child',
  'adult',
  'latin',
  'maths',
  'class',
  'chair',
  'photo',
  'stick',
  //'level',
  //'first',
  //'third',
  'shirt',
  'games',
  'lunch',
  'table',
  'socks',
  'duvet',
  'sheet',
  'horse',
  'staff',
  'group',
  'saxel',
]
